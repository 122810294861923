// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-404-tsx": () => import("./../../../src/containers/404.tsx" /* webpackChunkName: "component---src-containers-404-tsx" */),
  "component---src-containers-index-tsx": () => import("./../../../src/containers/index.tsx" /* webpackChunkName: "component---src-containers-index-tsx" */),
  "component---src-pages-bono-regalo-tsx": () => import("./../../../src/pages/bono-regalo.tsx" /* webpackChunkName: "component---src-pages-bono-regalo-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-delicake-tsx": () => import("./../../../src/pages/delicake.tsx" /* webpackChunkName: "component---src-pages-delicake-tsx" */),
  "component---src-pages-la-carta-tsx": () => import("./../../../src/pages/la-carta.tsx" /* webpackChunkName: "component---src-pages-la-carta-tsx" */),
  "component---src-pages-menus-tsx": () => import("./../../../src/pages/menus.tsx" /* webpackChunkName: "component---src-pages-menus-tsx" */),
  "component---src-pages-multimedia-tsx": () => import("./../../../src/pages/multimedia.tsx" /* webpackChunkName: "component---src-pages-multimedia-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */)
}

